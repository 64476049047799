@import "../../../styles//variables";

.wrap {
  position: relative;
  width: 100%;
  padding: 85px 0;

  .container {
    display: flex;
    background-color: #4378E8;
    padding: 40px 20px;
  }

  .title {
    color: #fff;
    white-space: nowrap;
    text-transform: uppercase;
  }

  .subtitle {
    color: #fff;
    font-weight: 400;
    text-transform: uppercase;
  }

  .list {
    display: grid;
    color: #fff;

    &Item {
      display: flex;
      justify-content: center;
      flex-direction: column;

      &Title {
        margin-bottom: 15px;
        font-weight: 400;
      }

      &Text {
        font-weight: 300;
      }
    }
  }

  @media screen and (min-width: $land-768) {
    .list {
      grid-template-columns: repeat(3, 1fr);
      gap: 12%;
      justify-content: space-between;
      border-left: 1px solid #fff;
      padding-left: 35px;
    }

    .subtitle {
      span {
        display: block;
      }
    }

    .container {
      border-radius: 20px;
      padding: 20px 4%;
    }

    .itemLeft {
      width: 260px;
      flex-shrink: 0;
      margin-right: 50px;
      padding: 20px 0 50px;
    }

    &.ru, &.de {
      .itemLeft {
        width: 360px;
      }

      .title {
        font-size: 36px;
      }

      .subtitle {
        font-size: 32px;
      }
    }
  }
  
  @media screen and (max-width: $land-767) {
    padding: 40px 0;

    .container {
      flex-direction: column;
      align-items: center;
    }

    .text {
      margin-top: 20px;
    }

    .itemLeft {
      text-align: center;
    }

    .list {
      grid-template-columns: 1fr;
      gap: 30px;
      margin-top: 35px;
      padding-top: 35px;
      border-top: 1px solid #fff;
      width: 100%;

      &Item {
        align-items: center;

        &Text {
          width: 60%;
          margin: 0 auto;
          text-align: center;
        }
      }
    }

    &.ru, &.de {
      .title {
        font-size: 30px;
      }

      .subtitle {
        font-size: 25px;
      }
    }
  }
}
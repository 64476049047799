@import '../../../styles/_variables';

.header {
  position: fixed;
  z-index: 999;
  width: 100%;
  padding: 0;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  height: 90px;
  border-bottom: 1px solid #676767;

  .nav {
    padding: 25px 0;
    background-color: transparent;
    margin: 0 auto;
    display: flex;
    align-items: center;
    box-sizing: border-box;
  }

  &Title {
    margin: 0;
    font-size: 2rem;
  }

  &Logo {
    width: auto;
    height: 40px;
    flex-shrink: 0;
  }

  &Menu {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .flex {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
    }
  
    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      // gap: 40px;
    }
  
    li {
      display: inline;
  
      .navItem {
        color: #000;
        text-decoration: none;
        font-size: 16px;
        line-height: 24px;
        padding: 8px 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.4s ease;

        &.linkActive,  &:hover {
          font-weight: 700;
        }
      }

      &.last {
        margin-left: 20px;
      }
    }
  }

  .mobileContent {
    display: none;
  }

  .languageSwitcher {
    position: relative;
  
    button {
      margin: 0;
      background: none;
      border: none;
      cursor: pointer;
      font-size: 16px;
      text-decoration: none;
      transition: all 0.5s ease;
    }

    svg {
      transition: all 0.5s ease;
      margin-left: 3px;
    }

    .langHide {
      display: none;
      position: absolute;
    }

    &:hover {
      svg {
        transform: rotate(-180deg);
      }

      .lang-hide {
        display: block;
      }
    }
  }

  .burger {
    display: flex;
    flex-direction: column;
    gap: 5px;
    cursor: pointer;

    span {
      width: 25px;
      height: 3px;
      background: $maintext;
      transition: all 0.3s;
    }

    &.burderOpen span:nth-child(1) {
      transform: rotate(45deg) translateY(6px);
    }
    &.burderOpen span:nth-child(2) {
      transform: rotate(-45deg) translateY(-6px);
    }
  }

  @media (max-width: $land-768) {
    height: 60px;

    .container {
      max-width: unset;
      height: 100%;
    }

    .burger {
      z-index: 2;
    }
    
    .nav {
      display: none;
      padding: 20px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      overflow-y: auto;
      border-radius: 0;
      z-index: 2;
      background-color: #fff;

      .headerMenu {
        flex-direction: column;

        .flex {
          flex-direction: column;
          row-gap: 20px;
          margin-top: 15px;
        }
      }

      &Open {
        display: block;

        .lang {
          display: none;
        }

        .last {
          margin-left: 0;
          margin-top: 20px;
        }
      }

      li {
    
        .navItem {
          font-size: 16px;
          line-height: 24px;
          padding: 8px 20px;
          border-radius: 60px;
        }
  
        &.last {
          margin-left: 20px;
        }
      }
    }

    &Menu {
    
      ul {
        flex-direction: column;
      }
    }

    .mobileContent {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 17px;
      height: 100%;

      .headerLink {
        margin-right: auto;
      }
    }
  }
}
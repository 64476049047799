@import "../../../styles/variables";

.wrap {
  position: relative;
  width: 100%;
  padding: 85px 0;

  .blockText {
    display: flex;
    align-items: flex-end;
    gap: 20px;
    justify-content: space-between;
  }

  .title {
    color: $colored;
    font-weight: 400;
    line-height: 150%;
  }

  .text {
    font-weight: 300;
  }

  .slider {
    .info {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &Wrap {
      width: 100%;
      margin-top: 40px;
    }
    &Btns {
      display: flex;
      gap: 30px;
      position: relative;
      z-index: 99;
    }

    &Btn {
      width: 50px;
      height: 50px;
      flex-shrink: 0;
      background-color: transparent;

      img {
        width: 100%;
        height: 100%;
      }

      &.sliderBtnPrev {
        transform: rotate(-180deg);
      }

      &.inactive {
        opacity: 0.5;
      }
    }

    &Item {
      border-radius: 40px;
      border: 1px solid rgba(0, 0, 0, 0.50);
      padding: 30px 38px;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 11px;

    .count {
      color: #282828;
      font-family: $inter;
      font-size: 30px;
      font-style: normal;
      font-weight: 300;
      line-height: 150%;

      span {
        font-weight: 600;
      }
    }
  }

  .slide {
    &Title {
      color: $maintext;
      margin: 0 0 15px;
    }

    &Text {
      margin-bottom: 50px;
    }
  }

  .btn {
    margin-top: auto;
  }
  
  @media screen and (min-width: 769px) {
    .text {
      max-width: 525px;
      width: 50%;
    }

    .title {
      span {
        display: block;
        font-size: 45px;
        color: $maintext;
        line-height: 1;
      }
    }

    .slider {
      &Item {
        max-width: 383px;
      }
    }
  }
  
  @media screen and (max-width: $land-768) {
    padding: 40px 0;

    .title {
      line-height: 100%;
    }

    .info {
      margin-bottom: 20px;
  
      .count {
        font-size: 14px;
      }
    }

    .container, .blockText {
      flex-direction: column;
      align-items: flex-start;
    }

    .slider {
      &Wrap {
        margin-top: 12px;
      }

      &Item {
        padding-left: 15px;
        padding-right: 15px;
      }

      &Btns {
        justify-content: space-between;
        gap: 15px;
      }

      &Btn {
        width: 35px;
        height: 35px;
      }
    }
  }
}
@import "../../../styles//variables";

.wrap {
  position: relative;
  width: 100%;
  padding: 85px 0;

  .container {
    display: flex;
    flex-direction: column;
    gap: 40px;
    max-width: 1520px;
  }

  .item {
    display: inline-flex;
    padding: 15px 50px;
    justify-content: center;
    align-items: center;
    margin: 0 33px;
    border-radius: 5px;
    background-color: #4378E8;
    color: #FFF;
    font-family: $bebas;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
  
  @media screen and (max-width: $land-767) {
    padding: 20px 0 40px;

    .container {
      gap: 20px;
    }

    .item {
      font-size: 14px;
      letter-spacing: -0.28px;
    }

  }
}
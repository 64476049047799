@import "../../../styles//variables";

.wrap {
  position: relative;
  width: 100%;
  padding: 85px 0;

  .title {
    color: $colored;
    font-weight: 400;
    text-transform: uppercase;
  }

  .subtitle {
    color: $maintext;
    font-weight: 400;
    text-transform: uppercase;
  }

  .text {
    font-weight: 300;
    color: $maintext;
  }

  .list {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 20px;

    &Wrap {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 19px;
      margin-top: 50px;
    }

    &Item {
      position: relative;
    }

    &Title {
      color: $maintext;
      font-weight: 600;
      line-height: 150%;
    }
  }

  .image {
    width: 100%;
  }
  
  @media screen and (min-width: $land-768) {
    .row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 30px;
    }

    .item {
      max-width: 490px;
      width: 50%;
    }

    .list {
      &Last {
        .listItem:last-child {
          border: 0;
          background: url("../../../assets/images/homepage/icon-border-last.svg") no-repeat center;
          background-size: 100%;
        }
      }
      
      &Item {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 43px 10px;
        border: 1px solid rgba(40, 40, 40, 0.30);
        
        &.first {
          border: 0;
          background: url("../../../assets/images/homepage/icon-border-first.svg") no-repeat center;
          background-size: 100%;
        }
      }
    }
  }
  
  @media screen and (max-width: $land-767) {
    padding: 40px 0;

    .text {
      margin-top: 20px;
    }

    .list {
      gap: 15px;

      &Wrap {
        grid-template-columns: 1fr;
        gap: 15px;
        margin-top: 15px;
      }

      &Item {
        padding-left: 25px;

        &:before {
          position: absolute;
          content: "";
          top: 50%;
          left: 5px;
          transform: translateY(-50%);
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: $maintext;
        }
      }
    }

  }
}
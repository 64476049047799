* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  max-width: 1260px;
  padding: 0 20px;
  margin: 0 auto;
  height: 100%;
}

.absolute {
  position: absolute;
}

.Dropdown-control {
  cursor: pointer;
  font-style: normal!important;
  font-size: 16px!important;
  font-weight: 300;
  font-family: "Roboto", sans-serif;
  background-color: transparent!important;
  border: none!important;
  color: rgba(0, 0, 0, 1)!important;
  text-transform: uppercase!important;
  padding: 0 10px!important;
  display: flex;
  align-items: center;
}

.Dropdown-root {
  position: relative;
}
.Dropdown-menu {
  background-color: white;
  border: 1px solid #ccc;
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
}
.Dropdown-arrow-wrapper {
  display: flex;
  align-items: center;
  margin-left: 7px;
}
.Dropdown-arrow {
  display: inline-block;
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDE2IDEwIiBmaWxsPSJub25lIj4KICA8cGF0aCBkPSJNMSAxTDguMjEyMTIgOEwxNSAxIiBzdHJva2U9ImJsYWNrIiBzdHJva2Utb3BhY2l0eT0iMC43IiBzdHJva2Utd2lkdGg9IjEuNSIvPgo8L3N2Zz4=");
  background-repeat: no-repeat;
  width: 10px;
  height: 6px;
  transition: all 0.4s ease;
  background-size: cover;
}
.Dropdown-option {
  box-sizing: border-box;
  color: rgba(51, 51, 51, 0.8);
  cursor: pointer;
  display: block;
  padding: 8px 10px;
}
.is-open .Dropdown-arrow {
  transform: rotate(180deg);
}

.mob_only {
  display: none!important;
}

.ovf-hidden {
  overflow: hidden;
}

.mt-btn {
  margin-top: 50px;
}

.zoomOut {
  animation: zoomOut 0.5s linear;
}

.zoomIn {
  animation: zoomIn 0.5s linear;
}

@keyframes zoomOut {
  from {
    transform: scale(1.2);
  }
  to {
    transform: scale(1);
  }
}

@keyframes zoomIn {
  from {
    transform: scale(0.8);
  }
  to {
    transform: scale(1);
  }
}

@media screen and (min-width: 769px) {
  .swiper-wrapper {
    display: flex;
  }
}

@media screen and (max-width: 767px) {

  .desk_only {
    display: none!important;
  }

  .mob_only {
    display: block!important;
  }

  .mt-btn {
    margin-top: 40px;
  }
}
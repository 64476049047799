$colored: #4378E8;
$maintext: #282828;

$bebas: "Bebas Neue", sans-serif;
$inter: "Inter", sans-serif;

$max-width: 1920px;
$main-width: 1220px;
$land-992: 992px;
$land-769: 769px;
$land-768: 768px;
$land-767: 767px;
$minwidth: 375px;
@import './_variables';

@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

.font-115-70 {
  font-family: $bebas;
  font-size: 115px;
  font-style: normal;
  font-weight: 400;
}
.font-80-50, .font-80-70, .font-80-35 {
  font-family: $bebas;
  font-size: 80px;
  font-style: normal;
  font-weight: 400;
}
.font-55-35 {
  font-family: $bebas;
  font-size: 55px;
  font-style: normal;
  font-weight: 400;
}
.font-45-28 {
  font-family: $bebas;
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
}

.font-30-28 {
  font-family: $bebas;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
}

.font-16-14 {
  font-family: $inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.font-16 {
  font-family: $inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.font-40-28 {
  font-family: $bebas;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.font-36-28 {
  font-family: $bebas;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.font-32-20, .font-32-24 {
  font-family: $bebas;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.font-30-16 {
  font-family: $bebas;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
}

.font-24-20 {
  font-family: $bebas;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.font-16-14 {
  font-family: $inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.font-20-16 {
  font-family: $inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.font-24-16 {
  font-family: $inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

@media screen and (max-width: $land-767) {
  .font-115-70, .font-80-70 {
    font-size: 70px;
  }
  .font-80-50 {
    font-size: 50px;
  }
  .font-80-35 {
    font-size: 35px;
  }

  .font-64-40 {
    font-size: 40px;
  }

  .font-55-35 {
    font-size: 35px;
  }

  .font-48-32 {
    font-size: 32px;
  }

  .font-48-26 {
    font-size: 26px;
  }

  .font-36-28, .font-40-28, .font-48-28, .font-45-28 {
    font-size: 28px;
  }

  .font-32-24 {
    font-size: 24px;
  }

  .font-32-20, .font-24-20 {
    font-size: 20px;
  }

  .font-16-14 {
    font-size: 14px;
  }

  .font-20-16, .font-24-16, .font-30-16 {
    font-size: 16px;
  }
}
@import "../../../styles//variables";

.wrap {
  position: relative;
  width: 100%;
  padding: 85px 0;
  
  .container {
    display: flex;
  }

  .title {
    color: $colored;
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 30px;
  }

  .text {
    font-weight: 300;
    color: $maintext;
    margin-top: 15px;
  }

  @media screen and (min-width: $land-768) {
    .container {
      align-items: center;
      justify-content: space-between;
      gap: 30px;
    }

    .title {
      span {
        display: block;
        font-size: 45px;
        color: $maintext;
      }
    }

    .item {
      max-width: 525px;
      width: 50%;

      .accwrap {
        max-width: 630px;
      }
    }
  }
  
  @media screen and (max-width: $land-767) {
    padding: 40px 0;

    .container {
      flex-direction: column;
      gap: 30px;
    }

    .text {
      margin-top: 12px;
    }

  }
}
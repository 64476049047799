@import '../../../styles/variables';

.accordion {
  position: relative;
  width: 100%;

  .item {
    background-color: transparent;
    padding: 15px 30px 15px 20px;
    border-bottom: 1px solid $maintext;

    &:last-child {
      margin-bottom: 0;
    }

    &Active {
      background-color: #EFEFEF;
      border-color: #EFEFEF;
    }
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 1;
    color: $maintext;
    font-family: $bebas;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;

    &.titleActive {

      .icon {
        transform: rotate(90deg);
      }
    }
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    flex-shrink: 0;
    cursor: pointer;
    transition: all 0.4s linear;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .content {
    color: $maintext;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%;
    padding-right: 16%;
    display: none;

    &.contentActive {
      display: block;
    }

    .text {
      margin-bottom: 15px;
    }

    ul {
      padding-left: 15px;
      li {
        position: relative;
        padding: 5px 10px 5px 20px;

        &:before {
          position: absolute;
          content: '';
          top: 13px;
          left: 0;
          width: 6px;
          height: 6px;
          background-color: $maintext;
          border-radius: 50%;
        }
      }
    }
  }

  @media (max-width: $land-768) {
    .item {
      padding: 15px 10px 15px 20px;
    }

    .title {
      column-gap: 20px;
    }

    .icon {
      width: 30px;
      height: 30px;
    }

    .content {
      font-size: 14px;
      padding-right: 40px;
    }
  }
}
@import "../../../styles//variables";

.wrap {
  position: relative;
  width: 100%;
  padding: 85px 0;

  .container {
    display: flex;
    background: #EFEFEF;
  }

  .title {
    color: $colored;
    font-weight: 400;
    white-space: nowrap;
    text-transform: uppercase;
  }

  .subtitle {
    color: $maintext;
    font-weight: 400;
    text-transform: uppercase;
  }

  .text {
    font-weight: 300;
    color: $maintext;
  }

  .image {
    width: 100%;
  }

  .mtBtn {
    margin-top: 40px;
  }

  .textMt {
    margin-top: 15px;
  }    
  
  &.ru {
    .title {
      font-size: 25px;
    }

    .subtitle {
      font-size: 20px;
    }
  }
  
  @media screen and (min-width: $land-768) {
    .container {
      align-items: center;
      justify-content: space-between;
      gap: 50px;
      padding: 0 0 0 6%;
      border-radius: 40px;
    }

    .item {
      max-width: 550px;
      width: 50%;
    }

    .text {
      width: 80%;
      margin-top: 25px;
    }

    &.ru {
      .title {
        font-size: 40px;
      }

      .subtitle {
        font-size: 35px;
      }
    }
  }
  
  @media screen and (max-width: $land-767) {
    padding: 40px 0;

    .container {
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
      padding: 35px 20px;
    }

    .text {
      margin-top: 20px;
    }

    .mtBtn {
      margin-top: 15px;
    }

    .imgWrap {
      text-align: right;
      width: 100%;
      padding-right: 45px;
    }

    .image {
      width: 246px;
      height: 212px;
      flex-shrink: 0;
    }
  }
}
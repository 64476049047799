@import "../../../styles//variables";

.wrap {
  position: relative;
  width: 100%;
  padding: 150px 0 35px;

  .row {
    display: flex;
  }

  .title {
    color: $colored;
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 30px;
  }

  .text {
    font-weight: 300;
    color: $maintext;
    margin-top: 15px;
  }

  .list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 19px;
    margin-bottom: 30px;

    .icon {
      width: 27px;
      height: 27px;
      flex-shrink: 0;
    }

    &Item {
      color: $maintext;
      font-weight: 300;
      line-height: 150%;
      display: flex;
      align-items: center;
      gap: 15px;
    }
  }

  .imgWrap {

    img {
      width: 100%;
    }
  }


  @media screen and (min-width: $land-768) {
    .row {
      align-items: center;
      justify-content: space-between;
      gap: 30px;
      margin: 50px 0;

      &Item {
        width: 50%;
        max-width: 500px;
      }

      &.rowFirst {
        .imgWrap {
          max-width: 690px;
          width: 70%;
        }
      }

      &.rowSec {
        .imgWrap {
          max-width: 625px;
          width: 65%;
        }
      }
    }

    .title {
      span {
        display: block;
        font-size: 45px;
        color: $maintext;
      }
    }

    .item {
      max-width: 525px;
      width: 50%;

      .accwrap {
        max-width: 630px;
      }
    }
  }
  
  @media screen and (max-width: $land-767) {
    padding: 100px 0 40px;

    .container, .row{
      flex-direction: column;
      gap: 30px;
    }

    .text {
      margin-top: 12px;
    }

    .list {
      gap: 15px;
      margin: 20px 0;
    }

  }
}
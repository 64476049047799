@import "../../../styles//variables";

.wrap {
  position: relative;
  padding: 85px 0;

  .container {
    overflow: hidden;
  }

  .list {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 60px;

    &Item {
      position: relative;
      display: flex;
      justify-content: space-between;
      width: 100%;

      &:nth-child(even) {
        flex-direction: row-reverse;

        .listItemTitle, .listItemText {
          margin-left: 80px;
        }
      }

      &Title {
        position: relative;
        color: $maintext;
        max-width: 500px;
      }
  
      &Text {
        margin-top: 15px;
        font-weight: 300;
        color: $maintext;
        line-height: 150%;
        max-width: 700px;
      }
    }

    .imgWrap {
      flex: 0 0 auto;

      img {
        width: 100%;
      }
    }

    .textBlock {
      flex: 1 1 auto;
      border-top: 1px solid rgba(0, 0, 0, 0.30);
      border-bottom: 1px solid rgba(0, 0, 0, 0.30);
      padding: 30px 0;
    }
  }
  
  @media screen and (max-width: $land-768) {
    padding: 40px 0;

    .list {
      margin-top: 50px;
      gap: 0;
  
      &Item {
        flex-direction: column;
  
        &:nth-child(even) {
          flex-direction: column;
  
          .listItemTitle, .listItemText {
            margin-left: 0;
          }
        }
  
        &Title {
          max-width: unset;
        }
    
        &Text {
          margin-top: 15px;
          max-width: unset;
        }
      }
  
      .textBlock {
        border-bottom: 0;
        padding: 40px 0;
        border: 0;
      }
    }
  }
}
@import "../../../styles//variables";

.wrap {
  position: relative;
  width: 100%;
  padding: 180px 0;

  .container {
    overflow: hidden;
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .title {
    color: $colored;

    span {
      color: $maintext;
    }
  }

  .text {
    color: $maintext;
  }

  .list {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 30px;

    &Item {
      position: relative;
      display: flex;
      justify-content: space-between;
      width: 100%;

      &:nth-child(even) {
        flex-direction: row-reverse;

        .listItemTitle, .listItemText {
          padding-left: 55px;
          padding-right: 0;
        }
      }

      &Title {
        position: relative;
        color: $colored;
      }
  
      &Text {
        margin-top: 15px;
        color: $maintext;
        line-height: 150%;
        padding-right: 50px;
      }
    }

    .imgWrap {
      flex: 0 0 auto;

      img {
        width: 100%;
      }
    }

    .textBlock {
      flex: 1 1 auto;
      border-top: 1px solid rgba(0, 0, 0, 0.30);
      border-bottom: 1px solid rgba(0, 0, 0, 0.30);
      padding: 40px 0;
    }
  }
  
  @media screen and (min-width: $land-768) {
    .title {
      max-width: 530px;
      width: 50%;
      span {
        display: block;
      }
    }

    .text {
      max-width: 530px;
      width: 50%;
    }
  }
  
  @media screen and (max-width: $land-768) {
    padding: 60px 0;

    .row {
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
    }

    .title {
      max-width: unset;
    }

    .list {
      margin-top: 50px;
      gap: 12px;
  
      &Item {
        flex-direction: column;
  
        &:nth-child(even) {
          flex-direction: column;
  
          .listItemTitle, .listItemText {
            padding-left: 0;
          }
        }
  
        &Title {
          max-width: unset;
        }
    
        &Text {
          margin-top: 12px;
          max-width: unset;
          padding: 0;
        }
      }
  
      .textBlock {
        border-bottom: 0;
        padding: 30px 0 40px;
      }
    }

  }
}
@import "../../../styles//variables";

.wrap {
  position: relative;
  width: 100%;
  padding: 130px 0 85px;

  .title {
    color: $colored;
    font-weight: 400;
    white-space: nowrap;
    text-transform: uppercase;
  }

  .subtitle {
    color: $maintext;
    font-weight: 400;
    text-transform: uppercase;
  }

  .text {
    font-weight: 300;
    color: $maintext;
  }

  .image {
    width: 100%;
  }

  .mtBtn {
    margin-top: 30px;
  }

  .textMt {
    margin-top: 15px;
  }
  
  @media screen and (min-width: $land-768) {
    .container {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 50px;
    }

    .item {
      max-width: 550px;
      width: 50%;
    }

    .text {
      padding-top: 10px;
    }
  }
  
  @media screen and (max-width: $land-767) {
    padding: 100px 0 40px;

    .container {
      display: flex;
      flex-direction: column;
      gap: 50px;
      padding: 0;
    }

    .item {
      text-align: center;
      padding: 0 20px;

      &Sec {
        order: 99;
        text-align: left;
      }
    }

    .title {
      font-size: 60px;
    }

    .text {
      margin-top: 15px;
    }

    .mtBtn {
      margin-top: 15px;
    }
  }
}
@import "../../../styles//variables";

.wrap {
  position: relative;
  width: 100%;
  padding: 190px 0 85px;

  .container {
    display: flex;
    background-color: #EFEFEF;
    max-width: 1220px;
  }

  .title {
    color: $colored;
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 30px;
  }

  .text {
    font-weight: 300;
    color: $maintext;
    margin-top: 15px;
  }

  .imgWrap {

    img {
      width: 100%;
    }
  }


  @media screen and (min-width: $land-768) {
    .container {
      border-radius: 40px;
      position: relative;
      padding: 60px 65px;
    }

    .item {
      max-width: 525px;
      width: 50%;
    }

    .imgWrap {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 0;
      max-width: 713px;
    }
  }
  
  @media screen and (max-width: $land-767) {
    padding: 60px 0 40px;

    .container {
      flex-direction: column;
      gap: 30px;
      padding: 30px 0 0;
    }

    .item:not(.imgWrap) {
      padding: 0 20px;
    }

    .title {
      text-align: center;
      padding: 0 60px;
    }

    .text {
      margin-top: 12px;
    }

  }
}
@import "../../../styles//variables";

.wrap {
  position: relative;
  width: 100%;
  padding: 85px 0;

  .container {
    display: flex;
  }

  .title {
    color: $colored;
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 30px;
  }

  .text {
    font-weight: 300;
    color: $maintext;
    margin-top: 15px;
  }

  .list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 19px;
    margin-top: 15px;

    &Title {
      font-weight: 700;
      color: $maintext;
      margin-top: 15px;
    }

    .icon {
      width: 27px;
      height: 27px;
      flex-shrink: 0;
    }

    &Item {
      color: $maintext;
      font-weight: 300;
      line-height: 150%;
      display: flex;
      align-items: center;
      gap: 15px;
    }
  }

  @media screen and (min-width: $land-768) {
    .container {
      align-items: flex-end;
      justify-content: space-between;
      gap: 40px;
    }

    .item {
      max-width: 500px;
      width: 50%;
    }
  }
  
  @media screen and (max-width: $land-767) {
    padding: 40px 0;

    .container {
      flex-direction: column;
      gap: 15px;
    }

    .list {
      grid-template-columns: 1fr;
      gap: 15px;
      margin-top: 15px;
    }

    .text {
      margin-top: 12px;
    }

  }
}
@import "../../../styles//variables";

.wrap {
  position: relative;
  width: 100%;
  padding: 85px 0;

  .title {
    color: $colored;
    font-weight: 400;
    text-transform: uppercase;
  }

  .subtitle {
    color: $maintext;
    font-weight: 400;
    text-transform: uppercase;
  }

  .text {
    font-weight: 300;
    color: $maintext;
    margin: 30px 0;
  }

  .list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 19px;
    margin-top: 30px;

    .icon {
      width: 27px;
      height: 27px;
      flex-shrink: 0;
    }

    &Title {
      color: $maintext;
      font-weight: 300;
      line-height: 150%;
      display: flex;
      align-items: center;
      gap: 15px;
    }
  }

  .image {
    width: 100%;
  }
  
  @media screen and (min-width: $land-768) {
    .row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 30px;
    }

    .item {
      max-width: 490px;
      width: 50%;
    }
  }
  
  @media screen and (max-width: $land-767) {
    padding: 40px 0;

    .text {
      margin-top: 20px;
    }

    .list {
      grid-template-columns: 1fr;
      gap: 15px;
      margin-top: 15px;
    }

  }
}
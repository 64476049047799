@import "../../../styles//variables";

.wrap {
  position: relative;
  width: 100%;
  padding: 0;
  margin: 90px 0 0;

  .container {
    position: relative;
  }

  .title {
    color: #fff;
    font-weight: 400;
    text-transform: uppercase;
  }
  .subtitle {
    color: #fff;
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 30px;
  }

  .text {
    font-weight: 300;
    color: #fff;
    margin-top: 15px;
  }

  .imgWrap {

    img {
      width: 100%;
    }
  }

  &.ru {
    .title {
      padding: 0;
    }
  }

  @media screen and (min-width: $land-768) {
    .container {
      border-radius: 40px;
      position: relative;
      padding: 100px 20px;
    }

    .item {
      max-width: 525px;
      width: 50%;
    }

    .imgDesk {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: right;
    }
  }
  
  @media screen and (max-width: $land-767) {
    padding: 60px 0 40px;
    margin-top: 0;

    .container {
      display: flex;
      flex-direction: column-reverse;
      gap: 30px;
      padding: 30px 0 0;
    }

    .item:not(.imgWrap) {
      padding: 0 20px;
    }

    .title {
      text-align: center;
      padding: 0 60px;
      color: #4378E8;
    }

    .text {
      margin-top: 12px;
      color: #000;
    }

    .centered {
      text-align: center;
    }
  }
}
@import "../../../styles//variables";

.wrap {
  position: relative;
  width: 100%;
  padding: 85px 0;

  .title {
    color: $maintext;
    font-weight: 400;
    margin-bottom: 30px;
    text-transform: uppercase;
  }

  .text {
    font-weight: 300;
    color: $maintext;
    margin: 15px 0 0;
  }

  .list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 19px;

    .icon {
      width: 55px;
      height: 55px;
      flex-shrink: 0;
    }

    .button {
      color: $maintext;
      font-weight: 400;
      line-height: 140%;
      display: flex;
      align-items: center;
      gap: 15px;
      transition: all 0.4s ease;

      &:hover {
        color: $colored;
      }
    }
  }

  @media screen and (min-width: $land-768) {
    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 30px;
    }

    .item {
      max-width: 550px;
      width: 50%;
    }
  }
  
  @media screen and (max-width: $land-767) {
    padding: 40px 0;

    .list {
      gap: 15px;
      margin-top: 15px;

      .icon {
        width: 28px;
        height: 28px;
      }
    }

  }
}
@import "../../../styles//variables";

.wrap {
  position: relative;
  width: 100%;
  padding: 85px 0;
  
  .title {
    color: $colored;
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 30px;
    line-height: 110%;

    span {
      color: $maintext;
    }
  }

  @media screen and (min-width: $land-768) {

    .title {
      span {
        display: block;
        font-size: 45px;
      }
    }

    .item {
      max-width: 525px;
      width: 50%;
    }
  }
  
  @media screen and (max-width: $land-767) {
    padding: 40px 0;

    .title {
      line-height: 100%;
    }

    &.ru {
      .title {
        font-size: 30px;
      }
    }
  }
}
@import "../../../styles//variables";

.wrap {
  position: relative;
  width: 100%;
  padding: 85px 0;

  .title {
    color: $colored;
    font-weight: 400;
    white-space: nowrap;
    text-transform: uppercase;
  }

  .subtitle {
    color: $maintext;
    font-weight: 400;
    text-transform: uppercase;
  }

  .text {
    font-weight: 300;
    color: $maintext;
    margin-top: 30px;

    &.textMt {
      margin-top: 15px;
    }
  }

  .image {
    width: 100%;
  }
  
  @media screen and (min-width: $land-767) {
    .container {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 50px;
    }

    .item {
      max-width: 550px;
      width: 50%;
    }

    .text {
      padding-right: 30px;
    }
  }
  
  @media screen and (max-width: $land-767) {
    padding: 40px 0;

    .container {
      display: flex;
      flex-direction: column;
      gap: 40px;
    }

    .text {
      margin-top: 15px;
    }

    .mtBtn {
      margin-top: 15px;
    }
  }
}